import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import TMDB from "../components/TMDB"

function Movies() {
  const [moviesPage, setMoviesPage] = useState([])

  useEffect(() => {
    async function loadingData() {
      const rawResponse = await fetch('https://movies.sebastienk.workers.dev/')

      const response = await rawResponse.json();
      const responseArray = response.results

      const filteredResult = responseArray.filter(el => el.overview.length > 2)

      setMoviesPage(filteredResult)

    }

    loadingData()

  }, []);
  const movieList = moviesPage.map((movie, i) => {
    let description = movie.overview
    if (description.length > 150) {
      description = description.slice(0, 150) + '...'
    }

    let fullImg
    if (movie.backdrop_path) {
      let moviesImg = "https://image.tmdb.org/t/p/w500" + movie.backdrop_path
      // removing width="300px" height="169px" need to do responsive img or nothing
      fullImg = <img style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, padding: 1 }} src={moviesImg} alt={movie.title} />
    } else {

      fullImg = <TMDB alt="The movie database " />
    }

    //window.innerWidth for desktop and screen.width for devices
    let deviceWidth
    if (window.innerWidth > 600) deviceWidth = "300px"
    else deviceWidth = "46vw"


    // return (<Movie key={i} movieName={movie.original_title} movieId={movie.id} movieDesc={description} movieImg={moviesImg} globalRating={movie.vote_average} globalCountRating={movie.vote_count} date={movie.release_date} vo={movie.original_language} genre={movie.genre_ids} />)
    return (< div key={i} style={{ display: "flex", flexDirection: "column", height: "400px", width: deviceWidth, backgroundColor: "rgb(235,235,235)", borderRadius: "10px", marginBottom: "10px", marginLeft: "5px", marginRight: "5px" }}>
      {fullImg}
      <h3 style={{ marginLeft: "10px", marginBottom: "10px", marginRight: "10px" }}>{movie.title}</h3>
      <p style={{ marginLeft: "10px", marginBottom: "10px" }}>Note : {movie.vote_average}/10</p>
      <p style={{ marginLeft: "10px", marginBottom: "10px", marginRight: "10px" }}>{description}</p>
    </div>)

  })

  return (<Layout>
    <Seo title="Films" />
    <h2 style={titleStyle}>Les films du moment au cinéma</h2>
    <div style={mainStyle}>
      {movieList}
    </div>
  </Layout>)


}
const titleStyle = { textAlign: "center", color: "rgb(235,235,235)" }
const mainStyle = { display: "flex", width: "98vw", flexWrap: "wrap", justifyContent: "center" }
export default Movies
